<template>
    <div class="main_loader">
        <div class="loader"></div>
    </div>
</template>

<style scoped>
.main_loader {
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    background-color: #ff7e00d4;
    display: flex;
    justify-content: center; */
  
}

.loader {
    border: 4px solid #333;
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
