<template>
  <div class="container-fluid home_swip">
    <swiper dir="ltr" :loop="true" :pagination="{
      clickable: true, 
    }" :spaceBetween="30" :centeredSlides="false" :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }" :speed="1000" :modules="modules" :changeLanguageDirection="swiperDirection" class="swiper"> -
      <!-- Slide Content -->

      <swiper-slide class="relative w-100 h-100">
        <img src="../../assets/images/Coffee_background.jpg" alt="Demo" />
        <div class="content opasi">
          <h1>{{ messages.messages.general.swipOneTitle }}</h1>
          <p>{{ messages.messages.general.swipTwo }}</p>
        </div>
        <div class="overlay"></div>
      </swiper-slide>
      <swiper-slide class="relative w-100 h-100">
        <img src="../../assets/images/Candy_background.jpg" alt="Demo" />
        <div class="content opasi">
          <h1>{{ messages.messages.general.swipThreeTitle }}</h1>
          <p>{{ messages.messages.general.swipFour }}</p>
        </div>
        <div class="overlay"></div>
      </swiper-slide>
      <swiper-slide class="relative w-100 h-100">
        <img src="../../assets/images/nuts_background.jpg" alt="Demo" />
        <div class="content opasi">
          <h1>{{ messages.messages.general.swipFiveTitle }}</h1>
          <p>{{ messages.messages.general.swipSix }}</p>
        </div>
        <div class="overlay"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';

import messageEn from '../../locales/en-US.json';
import messageAr from '../../locales/ar-AR.json';
import messageTr from '../../locales/tr-TR.json';
import EventBus from '../../event-bus'; // Import the event bus


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Autoplay, Pagination],
      currentLanguage: localStorage.getItem('language') || 'en',
      messages: this.getMessages(localStorage.getItem('language') || 'en'),
    };
  },
  computed: {
    swiperDirection() {
      return this.currentLanguage === 'ar' ? 'rtl' : 'ltr';
    },
    isRtl() {
      return this.currentLanguage === 'ar';
    },
  },
  methods: {
    getMessages(language) {
      switch (language) {
        case 'en':
          return messageEn;
        case 'ar':
          return messageAr;
        case 'tr':
          return messageTr;
        default:
          return messageEn;
      }
    },
    created() {
      // this.loadProducts();
      this.loadCategory();

      // Listen for language change events
      EventBus.on('language-changed', (newLanguage) => {
        this.currentLanguage = newLanguage;
        this.messages = this.getMessages(newLanguage);
      });
    },
    beforeUnmount() {
      // Clean up the event listener
      EventBus.off('language-changed');
    }
  }
}
</script>


<style scoped>
.slide-text {
  position: absolute;
  top: 28%;
  left: -46px;
  color: #fff;
  z-index: 999999;
  display: block;
  /* transition: 0.5s all; */
  animation: moveElement 1s linear;
}

.main-text {
  font-size: 40px;
}

.overlay {
  border-radius: 5px;
}

.slide-text>div {}

.moveElement {
  position: absolute;
  top: 40px;
  left: 46%;
  color: #fff;
  z-index: 999999;
  display: block;
  animation: moveElement1 1s linear;
}

@keyframes moveElement {
  0% {
    opacity: 0;
    top: 35%;
  }

  100% {
    opacity: 1;
    top: 28%;
  }
}

@keyframes moveElement1 {
  0% {
    opacity: 0;
    top: 150px;
  }

  100% {
    opacity: 1;
  }
}

.slide-text>p {
  /* color: #fff; */
  z-index: 999;
  /* position: absolute; */
}

.container-fluid {
  padding: 0px !important;
  position: relative;
}

.background_totla {
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

/* .swiper::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
} */
.home_swip {
  height: calc(80vh - 60px);

}

.swiper {
  width: 100%;
  height: 100%;
  padding: 25px 10px;
  border-radius: 5px;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

/* Additional CSS for RTL support */
.swiper[dir='rtl'] .swiper-slide {
  direction: rtl;
}


@media screen and (max-width: 992px) {
  .opasi h1 {
    width: 80%;
    font-size: 40px;
  }

  .opasi p {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .opasi h1 {
    font-weight: 600;
    font-size: 28px;
  }

  .opasi p {
    font-size: 16px;
  }

  .swiper {
    width: 100%;
    height: auto;
  }

  .home_swip {
    height: auto;
  }
}


@media screen and (max-width: 464px) {
  .opasi h1 {
    width: 100%;
    font-weight: 600;
    font-size: 28px;
  }

  .opasi p {
    width: 100%;
    font-size: 14px;
  }
}
</style>
