// store/modules/address.js

import axios from "axios";
const rootUrlApi = process.env.VUE_APP_API;
export default {
  namespaced: true,
  state() {
    return {
      data: [],
      addressDetails: [],
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
    },
    setAddressDetails(state, addressDetails) {
      state.addressDetails = addressDetails;
    },
  },
  actions: {
    async getAddressDetails({ commit }, { addressId, userId }) {
      console.log(userId);
      console.log("addressId", addressId);
      try {
        // Construct the URL to fetch address details by addressId and userId
        const url = `${rootUrlApi}/addresses?filters[id][$eq]=${addressId}&filters[user][id][$eq]=${userId}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
        });

        console.log("response response", response);

        // Commit a mutation to update the state with the fetched address details
        commit("setAddressDetails", response.data);
      } catch (error) {
        console.error(
          "Error fetching address details:",
          error.response ? error.response.data : error.message
        );
      }
    },
    async getAddressData({ commit }, { userId }) {
      console.log(commit);
      console.log(userId);
      try {
        // Make sure to include the userId in the query parameters or in the API endpoint as needed
        const response = await axios.get(
          `${rootUrlApi}/addresses?filters[user][id][$eq]=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
            },
          }
        );

        // Commit mutation to update the state
        commit("mutationProducts", response.data);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
      }
    },
    // async getAddressData( { commit }, { userId } ) {
    //   const userId = userId;
    //   console.log(userId);

    //   try {
    //     const response = await axios.get(
    //       "http://localhost:1337/api/addresses",
    //       {
    //         headers: {
    //           Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
    //         },
    //       }
    //     );

    //     // commit mutation to update the state
    //     commit("mutationProducts", response.data);
    //   } catch (error) {
    //     console.error(
    //       "Error fetching data:",
    //       error.response ? error.response.data : error.message
    //     );
    //   }
    // },
    async saveAddressData({ commit }, { userId, form }) {
      try {
        let dataString = JSON.stringify({
          data: {
            user: userId,
            title: form.title,
            full_name: form.username,
            phone_number: form.phone_number,
            country: form.country,
            city: form.city,
            address: form.address,
          },
        });

        const config = {
          method: "post",
          url: `${rootUrlApi}/addresses`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
          data: dataString,
        };

        // Post address data
        await axios.request(config);

        // const updatedData = [...state.data.data.map(item => ({ ...item })), form];

        // Fetch updated data
        const response = await axios.get(`${rootUrlApi}/addresses`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
        });

        const updatedData = response.data.data;

        commit("mutationProducts", updatedData);
      } catch (error) {
        console.error("Error saving data:", error);
      }
    },
    async updateAddressData({ commit }, { form, addressId }) {
      try {
        let data = JSON.stringify({
          data: {
            // user: userId,
            title: form.title,
            full_name: form.full_name,
            phone_number: form.phone_number,
            country: form.country,
            city: form.city,
            address: form.address,
          },
        });

        const config = {
          method: "put",
          url: `${rootUrlApi}/addresses/${addressId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
          data: data,
        };

        // Post address data
        await axios.request(config);

        // Fetch updated data
        const response = await axios.get(`${rootUrlApi}/addresses`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
        });

        const updatedData = response.data.data;

        commit("mutationProducts", updatedData);
      } catch (error) {
        console.error("Error saving data:", error);
      }
    },
    async deleteAddressData({ commit, state }, addressId) {
      try {
        const config = {
          method: "delete",
          url: `${rootUrlApi}/addresses/${addressId}`,
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
        };

        await axios.request(config);

        // Remove the deleted item from the state
        // const updatedData = state.data.filter((data) => data.id !== addressId);

        const updatedData = state.addressDetails.data.find(
          (item) => item.id == addressId
        );
        console.log(updatedData);
        commit("mutationProducts", updatedData);
      } catch (error) {
        console.error(
          "Error deleting data:",
          error.response ? error.response.data : error.message
        );
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.data;
    },
    addressDetails(state) {
      return state.addressDetails;
    },
  },
};
