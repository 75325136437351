import { createStore } from "vuex";

import product from "./modules/product.js";
import cart from "./modules/cart.js";
import category from "./modules/category.js";
import auth from "./modules/auth.js";
import address from "./modules/address.js";
import wishlist from "./modules/wishlist.js";
import checkout from "./modules/checkout.js";
import order from "./modules/order.js";

export default createStore({
  modules: {
    product: product,
    cart: cart,
    category: category,
    auth: auth,
    address: address,
    wishlist: wishlist,
    checkout: checkout,
    order: order,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
