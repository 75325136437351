<template>
    <!-- Mobile Footer -->
    <div class="apus-footer-mobile container-fluid p-3 d-block d-lg-none">
        <ul class="p-0 m-0 d-flex justify-content-between align-items-center">
            <li>
                <router-link to="/">
                    <i class="bi bi-house-door"></i>
                </router-link>
            </li>
            <li>
                <router-link to="/products">
                    <i class="bi bi-shop"></i>
                </router-link>
            </li>
            <li class="cart_mobile">
                <router-link class="footer-mini-cart mini-cart push_side header-icon relative" to="/cart">
                    <i class="bi bi-basket3"></i>
                    <span class="header__counter js-cart-count cart-count">{{ cartQuantity > 9 ? '+9' :
                        cartQuantity }}</span>
                </router-link>
            </li>
            <li>
                <router-link class="footer-search-btn push_side" to="" @click="goToUp">
                    <i class="bi bi-search"></i>
                </router-link>

            </li>
            <li>
                <router-link :to="authOrAccount" >
                    <i class="bi bi-person"></i>
                </router-link>
            </li>
        </ul>
    </div>
    <!-- Mobile Footer -->
</template>

<script>

export default {
    computed: {
        cartQuantity() {
            return this.$store.getters["cart/quantity"];
        },
        isLoggedIn() {
            return this.$store.getters['auth/isAuthenticated'];
        },
        authOrAccount() {
            if (this.isLoggedIn) {
                return '/account'
            } else {
                return '/auth/login'
            }
        }
    },

    methods: {
        goToUp() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },

    },
}
</script>


<style scoped>
.header__counter {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    background-color: var(--red-text-color);
    color: #fff;
    font-size: 12px;
    top: 0px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apus-footer-mobile {
    position: fixed;
    z-index: 99;
    background-color: #fff;
    padding: 15px 0;
    bottom: -1px;
    left: 0;
    right: 0;
    box-shadow: 0 0 25px #487bb740;
    border-radius: 10px 10px 0 0;
}

.apus-footer-mobile>ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex;
}

.apus-footer-mobile>ul>li {
    text-transform: uppercase;
    display: inline-flex;
    text-align: center;
    position: relative;
    -ms-flex: 1 0 20%;
    flex: 1 0 20%;
    align-items: center;
    justify-content: center;
}

.apus-footer-mobile ul li a {
    font-size: 16px;
    color: #222;
}

.apus-footer-mobile ul li a i {
    font-size: 23px;
}
</style>