<template>
    <div class="main_footer_box pt-5 pb-5">
        <footer-mobile></footer-mobile>
        <div class="container m-auto mb-5 row">
            <div class="col-12 col-lg-4 col-md-4">
                <router-link to="/">
                    <img class="mainLogo" src="../../assets/images/mainLogo.png" alt="">
                </router-link>
                <div class="d-flex flex-column gap-3">
                    <router-link to="/contact">{{ messages.messages.footer.contactUs }}</router-link>
                    <router-link to="/wishlist">{{ messages.messages.footer.wishlist }}</router-link>
                    <router-link to="/about">{{ messages.messages.footer.aboutUs }}</router-link>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-4 d-flex flex-column">
                <h4>{{ messages.messages.footer.Contracts }}</h4>
                <div class="d-flex flex-column gap-3">
                    <router-link to="/">{{ messages.messages.footer.salesContract }}</router-link>
                    <router-link to="/">{{ messages.messages.footer.membership }}</router-link>
                    <router-link to="/">{{ messages.messages.footer.privacy
                        }}</router-link>
                    <router-link to="/">{{ messages.messages.footer.cancellation
                        }}</router-link>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-md-4">
                <h4>{{ messages.messages.footer.popularCategories }}</h4>
                <div class="d-flex flex-wrap gap-3">
                    <router-link to="/" v-for="category in getCategory.data" :key="category.id">
                        {{ currentLanguage === 'ar' ?
                        category.attributes.title_ar :
                        currentLanguage === 'tr' ? category.attributes.title_tr :
                            category.attributes.title }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import messageEn from '../../locales/en-US.json';
import messageAr from '../../locales/ar-AR.json';
import messageTr from '../../locales/tr-TR.json';
import FooterMobile from './FooterMobile.vue';

export default {

    components: { FooterMobile },

    data() {
        return {
            currentLanguage: localStorage.getItem('language') || 'en', // Default language is English
            messages: this.getMessages(localStorage.getItem('language') || 'en') // Start with language from localStorage or English messages

        }
    },
    computed: {
        getCategory() {
            return this.$store.getters["category/getProducts"];
        },

    },
    methods: {
        async loadCategory() {
            this.isLoading = true;
            try {
                await this.$store.dispatch("category/actionProducts");
                this.isLoading = false;
            } catch (error) {
                console.error("Error loading products:", error);
                this.isLoading = false;
            }
        },
        getMessages(language) {
            switch (language) {
                case 'en':
                    return messageEn;
                case 'ar':
                    return messageAr;
                case 'tr':
                    return messageTr;
                default:
                    return messageEn; // Fallback to English if language is not recognized
            }
        },


    },
    watch: {
    },
    created() {
        // Set the initial language direction
        document.documentElement.dir = this.currentLanguage === 'ar' ? 'ltr' : 'ltr';
    },
    mounted() {
        this.loadCategory();

    }
}
</script>

<style scoped>
/* #ffe6c9 */
.main_footer_box {
    background-color: #ffe6c9;
}

a {
    color: #743c08;
}

h4 {
    margin-bottom: 20px;
    margin-top: 20px;
}
</style>