<template>
    <swiper :slidesPerView="6" :spaceBetween="30" :freeMode="true" :pagination="{
        clickable: true,
    }" :loop="canLoop" :speed="500" :breakpoints="{
        '340': {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        '640': {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        '768': {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        '1024': {
            slidesPerView: 5,
            spaceBetween: 30,
        },
    }" :modules="modules" class="swiper">
        <swiper-slide v-for="category in getNewProduct?.data" :key="category.id">
            <div class="loading_image relative">

                <div v-if="isLoading" class="contain_spinner">
                    <baseimage-spinner></baseimage-spinner>
                </div>
                <router-link :to="getProductDetails(category.id)">

                    <img class="swiper-slide_img"
                        :src="category?.attributes?.image?.data[0]?.attributes?.formats?.small?.url" alt="">
                    <div class="p-2">
                        <div class="mt-2"><span style="color: #743c08;"> {{ currentLanguage === 'ar' ?
        category.attributes.title_ar :
        currentLanguage === 'tr' ? category.attributes.title_tr :
            category.attributes.title }}</span>
                        </div>
                        <div class="price_old_price"><span class="price">{{ category.attributes.price }} TL</span> <span
                                class="old_price" v-if="category.attributes.old_price">{{
                                category.attributes.old_price }} TL</span></div>
                    </div>
                </router-link>
            </div>
        </swiper-slide>

    </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';


// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            baseUrlImage: process.env.VUE_APP_URL_IMAGE,
            modules: [FreeMode, Pagination],
            currentLanguage: localStorage.getItem('language') || 'en', // Default language is English
            isLoading: false,

        };
    },
    computed: {
        getNewProduct() {
            return this.$store.getters["product/newProducts"];
        },
        slidesPerView() {
            const categoryCount = this.getNewProduct?.data?.length || 0;
            return categoryCount >= 7 ? 7 : categoryCount;
        },
        canLoop() {
            return this.getNewProduct?.data?.length > this.slidesPerView;
        },
        breakpoints() {
            return {
                340: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: this.slidesPerView,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: this.slidesPerView,
                    spaceBetween: 40,
                },
            };
        },
    },
    methods: {
        async loadNewProduct() {
            this.isLoading = true;
            try {
                await this.$store.dispatch("product/loadNewProduct");
                this.isLoading = false;
            } catch (error) {
                console.error("Error loading products:", error);
                this.isLoading = false;
            }
        },
        getProductDetails(id) {
            return `/products/${id}`;
        }

    },
    mounted() {
        this.loadNewProduct();

    }
};
</script>


<style scoped>


.slide-text {
    position: absolute;
    top: 28%;
    left: -46px;
    color: #fff;
    z-index: 999999;
    display: block;
    /* transition: 0.5s all; */
    animation: moveElement 1s linear;
}

.main-text {
    font-size: 40px;
}

.overlay {
    border-radius: 5px;
}

.slide-text>div {}

.moveElement {
    position: absolute;
    top: 40px;
    left: 46%;
    color: #fff;
    z-index: 999999;
    display: block;
    animation: moveElement1 1s linear;
}

@keyframes moveElement {
    0% {
        opacity: 0;
        top: 35%;
    }

    100% {
        opacity: 1;
        top: 28%;
    }
}

@keyframes moveElement1 {
    0% {
        opacity: 0;
        top: 150px;
    }

    100% {
        opacity: 1;
    }
}

.old_price {
    text-decoration: line-through;
    color: #aaa;
}

.slide-text>p {
    /* color: #fff; */
    z-index: 999;
    /* position: absolute; */
}

.container-fluid {
    padding: 0px !important;
    position: relative;
}

.background_totla {
    width: 100%;
    height: 100%;
    background-color: #00000080;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}


.old_price {
    font-family: sans-serif;
    text-decoration: line-through;
    color: #aaa;
}

.price_old_price .price {
    color: #f89b2b;
    font-weight: 700;
}

/* .swiper::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
} */
.home_swip {
    height: calc(80vh - 60px);

}

.swiper {
    width: 100%;
    height: 100%;
    padding: 25px 10px;
    border-radius: 5px;

}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border: 1px solid #eaeaeaea;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    /* height: 100%; */
    object-fit: cover;
    border-radius: 5px;
}

/* Additional CSS for RTL support */
.swiper[dir='rtl'] .swiper-slide {
    direction: rtl;
}


@media screen and (max-width: 992px) {
    .opasi h1 {
        width: 80%;
        font-size: 40px;
    }

    .opasi p {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    .opasi h1 {
        font-size: 30px;
    }

    .opasi p {
        font-size: 16px;
    }
}


@media screen and (max-width: 464px) {
    .opasi h1 {
        width: 100%;
        font-size: 30px;
    }

    .opasi p {
        width: 100%;
        font-size: 16px;
    }
}
</style>
