<template>
    <!-- Start Product Cart -->
    <div class="relative product-collection mb-4">
        <baseimage-spinner v-if="isLoading"></baseimage-spinner>

        <base-button link :to="getProductDetails"
            class="link_product_items d-flex flex-column justify-content-between image_product h-100">
            <div class="">
                <img class="motion-reduce" loading="lazy" :src="this.image[0].attributes.formats.small.url" alt=""
                    draggable="false" />
                <!-- <div class="new_product">{{ is_new }}</div> -->
            </div>
            <div class="product_name_price p-2 w-100">
                <div class="product_name mt-2 mb-2 text-center">{{ title }}</div>
                <div class="price_old_price text-center">
                    <span class="price">{{ thePriceHandling }} TL</span>
                    <span v-if="old_price" class="old_price"> {{ old_price }} TL</span>
                </div>
            </div>
        </base-button>
    </div>
    <!-- End Product Cart -->
</template>

<script>
import messageEn from '../../locales/en-US.json';
import messageAr from '../../locales/ar-AR.json';
import messageTr from '../../locales/tr-TR.json';
import EventBus from '../../event-bus'; // Import the event bus

export default {
    components: {
    },
    data() {
        return {
            baseUrlImage: process.env.VUE_APP_URL_IMAGE,
            oldAndNew: null,
            is_old_price: false,
            heart_outline: 'heart-outline',
            heart: 'heart',
            is_opend: false,
            hovered: false,
            isLoading: false,
            qty: 1,
            is_added_to_cart: false,
            addedClass: false,
            addedClassCompare: false,
            currentLanguage: localStorage.getItem('language') || 'en',
            messages: this.getMessages(localStorage.getItem('language') || 'en')
        };
    },
    props: {
        id: { typeof: Number.toString(), required: true },
        title: { typeof: String },
        price: { typeof: Number },
        image: { typeof: Object },
        old_price: { typeof: Number },
        is_new: { typeof: Boolean },
    },
    computed: {
        selectedProduct() {
            return this.$store.getters["product/getProducts"];
        },
        cartItems() {
            return this.$store.getters['cart/products'];
        },
        thePriceHandling() {
            let numberString = this.price.toString();
            let [integerPart, decimalPart] = numberString.split(".");
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
            return formattedNumber;
        },
        is_has_value() {
            if (this.$props.old_price) {
                return true;
            } else {
                return false;
            }
        },
        newPrice() {
            const old_price = this.$props.old_price;
            if (old_price) {
                const result1 = this.$props.old_price - this.$props.price;
                const result = result1 / this.$props.old_price;
                const result2 = result * 100;
                return Math.round(result2);
            } else {
                return old_price
            }
        },
        getProductDetails() {
            return `/products/${this.id}`;
        }
    },
    methods: {
        async simulateAddToCart() {
            // Simulate a delay for the add-to-cart action
            return new Promise((resolve) => setTimeout(resolve, 1000));
        },
        async addToCart() {
            this.isLoading = true;
            this.is_added_to_cart = false; // Reset the message
            try {
                // Perform the add-to-cart action
                await this.$store.dispatch("cart/addToCart", { id: this.id, qty: this.qty });
                // Simulate add-to-cart action (e.g., API call)
                await this.simulateAddToCart();
                // Set message to show after item is added
                this.is_added_to_cart = true;
                // Hide the message after a few seconds
                setTimeout(() => {
                    this.is_added_to_cart = false;
                }, 2000); // Message visible for 2 seconds
            } catch (error) {
                console.error("Error adding to cart:", error);
            } finally {
                this.isLoading = false;
            }
        },
        addProductToWishlist() {
            //   this.$store.dispatch("wishlist/addToWishlist",
            //     this.id,
            //     );
            this.addedClass = !this.addedClass;
        },
        removeProductFromWishlist() {
            this.$store.dispatch("wishlist/removeFromWishlist", {
                id: this.id,
            });
            this.addedClass = !this.addedClass;
        },

        // addToCompare() {
        //     this.$store.dispatch('compare/addToCompare', this.id);
        //     this.addedClassCompare = !this.addedClassCompare;
        // },
        // removeProductCompare() {
        //     this.$store.dispatch("compare/removeFromCompare", {
        //         id: this.id,
        //     });
        //     this.addedClassCompare = !this.addedClassCompare;
        // },
        getMessages(language) {
            switch (language) {
                case 'en':
                    return messageEn;
                case 'ar':
                    return messageAr;
                case 'tr':
                    return messageTr;
                default:
                    return messageEn;
            }
        }
    },
    created() {
        // Listen for language change events
        EventBus.on('language-changed', (newLanguage) => {
            this.currentLanguage = newLanguage;
            this.messages = this.getMessages(newLanguage);
        });
    },
    beforeUnmount() {
        // Clean up the event listener
        EventBus.off('language-changed');
    }
}
</script>

<style scoped>
.button-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.product_name {
    color: #743c08;
    font-weight: 600;
    font-size: 16px;
}

.loader {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.is_added_to_cart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #000;
    font-weight: 700;
}

.link_product_items {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border: 1px solid #eaeaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
}





.motion-reduce {
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 5px;

}

.product-collection {
    /* height: 350px; */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        box-shadow: 0 -1px 0 #000;
    }

    50% {
        transform: rotate(180deg);
        box-shadow: 0 -1px 0 #000;
    }

    100% {
        transform: rotate(360deg);
        box-shadow: 0 -1px 0 #000;
    }
}




.old_price {
    font-family: sans-serif;
    text-decoration: line-through;
    color: #aaa;
}

.price_old_price {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    flex-direction: row;
}

.price_old_price .price {
    color: var(--second-main-color);
    font-weight: 700;
}

@media screen and (max-width: 464px) {
    .media {
        height: 100px;
    }

    .product_name_price {
        font-size: 14px;
    }

    .product_name {
        font-size: 15px;
    }
}
</style>