<template>
  <div>
    <!-- <button @click="addToCard">Add To Card</button> -->
    <button><slot></slot></button>
  </div>
</template>
<script>

</script>

<style scoped>
button {
  color: #fff;
  background-color: #ff6000;
  text-align: center;
  padding: 5px 15px;
  font-weight: 600;
  outline: none;
  border: none;
  border-radius: 12px;
  float: right;
}
</style>
