import axios from "axios";

export default {
  namespaced: true,
  state() {
    const wishlist = JSON.parse(sessionStorage.getItem("wishlist")) || [];
    return {
      data: JSON.parse(sessionStorage.getItem("productData")) || [],
      items: wishlist,
      qty: wishlist.reduce((sum, item) => sum + (item.qty || 0), 0),
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
      console.log("mutationProducts state.data", state.data);
      sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    removeProductFromWishlist(state, payload) {
      const prodId = payload;
      const productIndex = state.items.findIndex(
        (cartItem) => cartItem.productId === prodId
      );
      if (productIndex !== -1) {
        state.items.splice(productIndex, 1);
        state.qty = state.items.reduce((sum, item) => sum + (item.qty || 0), 0);
        sessionStorage.setItem("wishlist", JSON.stringify(state.items));
      }
    },
    addProductToWishlist(state, payload) {
      const productData = payload;
      // Check if the product already exists in the wishlist
      const productInWishlist = state.items.find(
        (item) => item.id === productData.id
      );
      if (productInWishlist) {
        // If product already exists, do not add it again
        return;
      }
      const newItem = {
        id: productData.id,
        title: productData.attributes.title,
        price: productData.attributes.price,
        old_price: productData.attributes.old_price,
        desc: productData.attributes.desc,
        image: productData.attributes.image,
        addedToWishlist: true, // Mark product as added to wishlist
        qty: 1,
      };
      state.items.push(newItem);
      state.qty++;
      sessionStorage.setItem("wishlist", JSON.stringify(state.items));
    },

    // removeProductFromWishlist(state, payload) {
    //   const prodId = payload;
    //   const productIndex = state.items.findIndex(
    //     (cartItem) => cartItem.id === prodId
    //   );
    //   if (productIndex !== -1) {
    //     state.items.splice(productIndex, 1);
    //     state.qty--;
    //     sessionStorage.setItem("wishlist", JSON.stringify(state.items));
    //   }
    // },
  },
  actions: {
    async actionProducts(context, foundProduct) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API +
            `/products?populate=*&filters[id][$eq]=${foundProduct}`,
          {
            headers: {
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
            },
          }
        );
        const responseData = response.data;
        console.log("response.data", responseData);
        await context.commit("mutationProducts", responseData);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          console.log(error.request);
          throw new Error("No response received");
        } else {
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async addToWishlist(context, payload) {
      const prodId = payload.id;
   
    //   const products = context.state.data
    //   console.log('products productsproducts',products)
      try {
        await context.dispatch("actionProducts", prodId);

        const products = context.getters.getProducts;

        const product = products.data.find((prod) => prod.id == prodId);
        if (product) {
          context.commit("addProductToWishlist", product);
        } else {
          console.error("Product not found in products data");
        }
      } catch (error) {
        console.error("Error adding product to cart:", error);
      }
    },
    removeFromWishlist(context, payload) {
      context.commit("removeProductFromWishlist", payload);
    },
  },
  getters: {
    getProducts(state) {
      return state.data;
    },
    productsWishlist(state) {
      return state.items;
    },
    // quantity(state) {
    //   return state.qty;
    // },
  },
};
