import axios from "axios";
import api from "../utils/globalApi";
export default {
  namespaced: true,
  state() {
    return {
      data: [],
      selectedProduct: [],
      newData: [],
      offerData: [],
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    mutationDetailsProducts(state, payload) {
      state.selectedProduct = payload;
      sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    mutationSearchProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    mutationIs_newProducts(state, payload) {
      state.newData = payload;
      sessionStorage.setItem("newData", JSON.stringify(payload));
    },
    mutationOfferProducts(state, payload) {
      state.offerData = payload;
      sessionStorage.setItem("offerData", JSON.stringify(payload));
    },
  },
  actions: {
    async getQueryDetailsProduct(context, id) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API +
            `/products?populate=*&filters[id][$eq]=${id}`,
          {
            headers: {
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
            },
          }
        );
        const responseData = response.data;
        await context.commit("mutationDetailsProducts", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async getSearchProduct(context, foundProduct) {
      const lowerCaseFoundProduct = foundProduct.toLowerCase();
      const encodedProduct = encodeURIComponent(lowerCaseFoundProduct);

      // Construct the base URL for the product searchlet baseURL = `${process.env.VUE_APP_API}/products?populate=*`;
      let baseURL = `${process.env.VUE_APP_API}/products?populate=*`;

      baseURL += `&filters[$or][0][title][$containsi]=${encodedProduct}`;
      baseURL += `&filters[$or][1][title_ar][$containsi]=${encodedProduct}`;
      baseURL += `&filters[$or][2][title_tr][$containsi]=${encodedProduct}`;

      // Construct the base URL for the product search
      // baseURL += `&filters[title][$contains]=${encodedProduct}`;

      // // Fetch categories
      // const categoryResponse = await axios.get(
      //   `${process.env.VUE_APP_API}/categories?fields[0]=title`,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
      //     },
      //   }
      // );
      // const categories = categoryResponse.data.data.map((category) =>
      //   category.attributes.title.toLowerCase()
      // );
      // // Fetch brands
      // const brandResponse = await axios.get(
      //   `${process.env.VUE_APP_API}/products?fields[0]=title`,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
      //     },
      //   }
      // );
      // const brands = brandResponse.data.data.map((brand) =>
      //   brand.attributes.title.toLowerCase()
      // );
      // // Add category filter if foundProduct matches any category
      // if (categories.includes(lowerCaseFoundProduct)) {
      //   baseURL += `&filters[category][title][$eq]=${encodedProduct}`;
      // }

      // // Add brand filter if foundProduct matches any brand
      // if (brands.includes(lowerCaseFoundProduct)) {
      //   baseURL += `&filters[products][title][$eq]=${encodedProduct}`;
      // }

      try {
        // Fetch the products
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
          },
        });
        const responseData = response.data;
        await context.commit("mutationSearchProducts", responseData);
        // Return whether any products were found
      } catch (error) {
        // Handle errors here
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          console.log(error.request);
          throw new Error("No response received");
        } else {
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    // async getSearchProduct(context, foundProduct) {
    //   let baseURL = process.env.VUE_APP_API + `/productsses?populate=*`;

    //   let categoryFilter = process.env.VUE_APP_API + `/categories?fields[0]=title`;
    //   // let brandFilter = process.env.VUE_APP_API + `/brands?fields[0]=title`;

    //   categoryFilter.forEach((category) => {
    //     baseURL += `&filters[category][title][$eq]=${category}`;
    //   });

    //   // Construct the final URL with both filters
    //   let url = `${baseURL}`;

    //   console.log('URL:', url);

    //   try {
    //     const response = await axios.get(url, {
    //       headers: {
    //         Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
    //       },
    //     });

    //     // Category Title Data

    //     const responseData = response.data;
    //     console.log("search Product", foundProduct);
    //     await context.commit("mutationSearchProducts", responseData);
    //   } catch (error) {
    //     // Handle errors here
    //     if (error.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       console.log(error.response.data);
    //       console.log(error.response.status);
    //       console.log(error.response.headers);
    //       throw new Error(error.response.data.message || "Network error");
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //       // http.ClientRequest in node.js
    //       console.log(error.request);
    //       throw new Error("No response received");
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.log("Error", error.message);
    //       throw new Error(error.message || "Error setting up the request");
    //     }
    //   }
    // },

    async actionProducts(
      context,
      { pageNumber = 1, sort = "", pageSize = 25, filters = {} } = {}
    ) {
      pageNumber = isNaN(pageNumber) ? 1 : pageNumber;
      let url =
        process.env.VUE_APP_API +
        `/products?populate=*&pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}&sort=${sort}`;

      if (filters.categories && filters.categories.length) {
        filters.categories.forEach((category) => {
          url += `&filters[category][title][$eq]=${category}`;
        });
      }
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
          },
        });
        const responseData = response.data;
        // console.log(responseData);
        await context.commit("mutationProducts", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async loadNewProduct(context) {
      try {
        const response = await api.loadNewProduct();
        const responseData = response.data;

        await context.commit("mutationIs_newProducts", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async offerDataProducts(context) {
      try {
        const response = await api.offerDataProducts();
        const responseData = response.data;

        await context.commit("mutationOfferProducts", responseData);
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.data;
    },
    getProductDetails(state) {
      return state.selectedProduct;
    },
    getSearchProduct(state) {
      return state.data;
    },
    newProducts(state) {
      return state.newData;
    },
    offerProducts(state) {
      return state.offerData;
    },
  },
};
