<template>
  <div class="">
    <home-swip></home-swip>
    <div class="container">
      <h2 class="main_title_por_home text-center mt-4 mb-4">{{ messages.messages.shop.newProducts }}</h2>
      <swip-newporduct></swip-newporduct>
    </div>
    <div class="container">
      <h2 class=" main_title_por_home text-center mt-4 mb-4">{{ messages.messages.shop.offers }}</h2>
      <swip-offers></swip-offers>
    </div>
    <more-secions></more-secions>
  </div>
</template>

<script>


import messageEn from '../locales/en-US.json';
import messageAr from '../locales/ar-AR.json';
import messageTr from '../locales/tr-TR.json';
import EventBus from '../event-bus'; // Import the event bus
import MoreSecions from '@/components/MoreSection.vue';

import SwipNewporduct from '@/components/layout/SwipNewporduct.vue';

import SwipOffers from '@/components/layout/SwipOffers.vue';

import HomeSwip from '@/components/layout/HomeSwip.vue';

export default {
  data() {
    return {
      currentLanguage: localStorage.getItem('language') || 'en', // Default language is English
      messages: this.getMessages(localStorage.getItem('language') || 'en'),

    }
  },
  components: {
    HomeSwip, SwipNewporduct, SwipOffers,MoreSecions
  },
  computed: {


  },
  methods: {
    getMessages(language) {
      switch (language) {
        case 'en':
          return messageEn;
        case 'ar':
          return messageAr;
        case 'tr':
          return messageTr;
        default:
          return messageEn;
      }
    },
  },
  created() {

    // Listen for language change events
    EventBus.on('language-changed', (newLanguage) => {
      this.currentLanguage = newLanguage;
      this.messages = this.getMessages(newLanguage);
    });
  },
  beforeUnmount() {
    // Clean up the event listener
    EventBus.off('language-changed');
  },
}
</script>

<style scoped>
.slide-text {
  position: absolute;
  top: 28%;
  left: -46px;
  color: #fff;
  z-index: 999999;
  display: block;
  /* transition: 0.5s all; */
  animation: moveElement 1s linear;
}

.main-text {
  font-size: 40px;
}

.overlay {
  border-radius: 5px;
}

h2 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #aaa;
}

.slide-text>div {}

.moveElement {
  position: absolute;
  top: 40px;
  left: 46%;
  color: #fff;
  z-index: 999999;
  display: block;
  animation: moveElement1 1s linear;
}

@keyframes moveElement {
  0% {
    opacity: 0;
    top: 35%;
  }

  100% {
    opacity: 1;
    top: 28%;
  }
}

@keyframes moveElement1 {
  0% {
    opacity: 0;
    top: 150px;
  }

  100% {
    opacity: 1;
  }
}

.slide-text>p {
  /* color: #fff; */
  z-index: 999;
  /* position: absolute; */
}

.container-fluid {
  padding: 0px !important;
  position: relative;
}

.background_totla {
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

/* .swiper::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
} */
.home_swip {
  /* height: calc(80vh - 60px); */

}

.swiper {
  width: 100%;
  height: 100%;
  padding: 25px 10px;
  border-radius: 5px;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

/* Additional CSS for RTL support */
.swiper[dir='rtl'] .swiper-slide {
  direction: rtl;
}


@media screen and (max-width: 992px) {
  .opasi h1 {
    width: 80%;
    font-size: 40px;
  }

  .opasi p {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .opasi h1 {
    font-size: 30px;
  }

  .opasi p {
    font-size: 16px;
  }

  .main_title_por_home {
    font-size: 30px;
  }
}


@media screen and (max-width: 464px) {
  .opasi h1 {
    width: 100%;
    font-weight: 600;
    font-size: 28px;
  }

  .opasi p {
    width: 100%;
    font-size: 14px;
  }
}
</style>
