<template>
    <span class="" style="color: var(--second-main-color);">{{ thePriceHandling }}
        TL</span>
</template>

<script>
export default {
    props: ['price', 'hellValue', 'weightValue'],
    computed: {
        hendelWeightValue() {
            const weightValue = +this.weightValue / 1000 * this.getPriceHellValue;
            return weightValue;
        },
        getPriceHellValue() {
            return this.price + this.hellValue;
        },
        getTotalPrice() {
            return this.hendelWeightValue == 0 ? this.getPriceHellValue : this.hendelWeightValue;
        },
        thePriceHandling() {
            let numberString = this.getTotalPrice.toString();
            let [integerPart, decimalPart] = numberString.split(".");
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
            return formattedNumber;
        },
    },
    methods: {
   
    },

}

</script>