<template>
    <swiper :slidesPerView="7" :spaceBetween="30" :freeMode="true" :pagination="{
        clickable: true,
    }" :loop="canLoop" :autoplay="{
        delay: 1500,
        disableOnInteraction: false,
    }" :speed="500" :breakpoints="{
        '340': {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        '640': {
            slidesPerView: 5,
            spaceBetween: 20,
        },
        '768': {
            slidesPerView: 7,
            spaceBetween: 30,
        },
        '1024': {
            slidesPerView: 8,
            spaceBetween: 40,
        },
    }" :modules="modules" class="swiper">


        <swiper-slide v-for="category in getCategory.data" :key="category.id">
            <div class="loading_image relative">
                <div v-if="isLoading" class="contain_spinner">
                    <baseimage-spinner></baseimage-spinner>
                </div> 
                <router-link to="/products">
                    <img :src="category?.attributes?.image?.data?.[0]?.attributes?.formats?.small?.url ?
        category.attributes.image.data[0].attributes.formats.small.url :
        '/path/to/fallback/image.jpg'" alt="" />
                    <div class="mt-2"><span style="color: #743c08;"> {{ currentLanguage === 'ar' ?
        category.attributes.title_ar :
        currentLanguage === 'tr' ? category?.attributes.title_tr :
            category?.attributes.title }}</span>
                    </div>
                </router-link>
            </div>
        </swiper-slide>

    </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';


// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            baseUrlImage: process.env.VUE_APP_URL_IMAGE,
            modules: [Autoplay, FreeMode, Pagination],

            currentLanguage: localStorage.getItem('language') || 'en', // Default language is English
            // messages: this.getMessages(localStorage.getItem('language') || 'en') // Start with language from localStorage or English messages
            isLoading: false,

        };
    },
    computed: {
        getCategory() {
            return this.$store.getters["category/getProducts"];
        },
        slidesPerView() {
            const categoryCount = this.getCategory?.data?.length || 0;
            return categoryCount >= 7 ? 7 : categoryCount;
        },
        canLoop() {
            return this.getCategory?.data?.length > this.slidesPerView;
        },
        breakpoints() {
            return {
                340: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: this.slidesPerView,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: this.slidesPerView,
                    spaceBetween: 40,
                },
            };
        },
    },
    methods: {
        async loadCategory() {

            try {
                this.isLoading = true;
                await this.$store.dispatch("category/actionProducts");
                this.isLoading = false;
            } catch (error) {
                console.error("Error loading products:", error);
                this.isLoading = false;
            }
        },


    },
    mounted() {
        this.loadCategory();

    }
};
</script>


<style scoped>
.loading_image {
    /* border: 1px ; */
    box-shadow: 0px 0px 8px #eaeaea;
    border-radius: 5px;
}


.contain_spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.main-text {
    font-size: 40px;
}

.overlay {
    border-radius: 5px;
}



.container-fluid {
    padding: 0px !important;
    position: relative;
}

.background_totla {
    width: 100%;
    height: 100%;
    background-color: #00000080;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

/* .swiper::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
} */
.home_swip {
    height: calc(80vh - 60px);

}

.swiper {
    width: 100%;
    height: 100%;
    padding: 25px 10px;
    border-radius: 5px;

}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fffaf4;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
}

/* Additional CSS for RTL support */
.swiper[dir='rtl'] .swiper-slide {
    direction: rtl;
}


@media screen and (max-width: 992px) {
    .opasi h1 {
        width: 80%;
        font-size: 40px;
    }

    .opasi p {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    .opasi h1 {
        font-size: 30px;
    }

    .opasi p {
        font-size: 16px;
    }
}


@media screen and (max-width: 464px) {
    .opasi h1 {
        width: 100%;
        font-size: 30px;
    }

    .opasi p {
        width: 100%;
        font-size: 16px;
    }
}
</style>
