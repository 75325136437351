import axios from "axios";
const rootUrlApi = process.env.VUE_APP_API;

let timer;
export default {
  namespaced: true,
  state() {
    return {
      user: JSON.parse(localStorage.getItem("user") || null),
      userId: localStorage.getItem("userId") || null,
      token: localStorage.getItem("token") || null,
      didAutoLogout: false,
      // addresses: [],
    };
  },
  mutations: {
    setUser(state, payload) {
      state.token = payload.token;
      state.user = payload.user;
      state.userId = payload.userId;
      state.didAutoLogout = false;
    },
    setAutoLogout(state) {
      state.didAutoLogout = true;
    },
  },
  actions: {
    async login(context, payload) {
      await context.dispatch("auth", {
        ...payload,
        mode: "login",
      });
    },
    async signup(context, payload) {
      try {
        const response = await context.dispatch("auth", {
          ...payload,
          mode: "signup",
        });

        if (response.success) {
          // Registration was successful but no automatic login is performed
          // You can show a message or redirect to the login page, e.g.,
          return {
            success: true,
            message: "Registration successful! Please log in.",
          };
        } else {
          return { success: false, message: response.message };
        }
      } catch (error) {
        console.error("Signup failed:", error);
        return { success: false, message: "Signup failed." };
      }
    },
    async auth(context, payload) {
      const mode = payload.mode;
      const { username, email, password } = payload;
      let url = "";

      if (mode === "login") {
        url = `${rootUrlApi}/auth/local`;
      } else if (mode === "signup") {
        url = `${rootUrlApi}/auth/local/register`;
      }

      let config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data:
          mode === "signup"
            ? { username, email, password }
            : { identifier: email, password },
      };

      try {
        const response = await axios.request(config);
        const responseData = response.data;
        if (mode === "login") {
          // Only save user data and commit if the mode is "login"
          localStorage.setItem("token", responseData.jwt);
          localStorage.setItem("userId", responseData.user.id);
          localStorage.setItem("user", JSON.stringify(responseData.user));

          context.commit("setUser", {
            token: responseData.jwt,
            userId: responseData.user.id,
            user: responseData.user,
          });
        }
        return { success: true }; // Indicate success to the component
      } catch (error) {
        let errorMessage = "An unexpected error occurred.";

        if (error.response && error.response.data) {
          // Check if the error message from the backend indicates that the email already exists
          const backendMessage = error.response.data.error.message;

          if (Array.isArray(backendMessage) && backendMessage.length > 0) {
            errorMessage = backendMessage[0].messages[0].message;
          } else if (typeof backendMessage === "string") {
            errorMessage = backendMessage;
          }

          console.log("Backend error message:", errorMessage);
        } else if (error.message) {
          errorMessage = error.message;
          console.log("errorMessage11", errorMessage);
        }

        return {
          success: false,
          message: errorMessage,
        }; // Return error details to the component
      }
    },
    // async fetchUserAddresses(context, userId) {
    //   try {
    //     const response = await axios.get(
    //       `${rootUrlApi}/addresses?filters[user][id][$eq]=${userId}`
    //     );
    //     const addresses = response.data;

    //     // Commit the addresses to the Vuex store or handle them as needed
    //     context.commit("setAddresses", addresses);
    //   } catch (error) {
    //     console.error("Failed to fetch user addresses:", error);
    //     // Handle error
    //   }
    // },
    // async auth(context, payload) {
    //   const mode = payload.mode;
    //   const { username, email, password } = payload;
    //   let url = "";

    //   if (mode === "login") {
    //     url = "http://localhost:1337/api/auth/local";
    //   } else if (mode === "signup") {
    //     url = "http://localhost:1337/api/auth/local/register";
    //   }

    //   let config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     url: url,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: { identifier: email, password }, // Use identifier for login
    //   };
    //   // For signup, include the username in the data
    //   console.log("config", config);
    //   if (mode === "signup") {
    //     config.data.username = username;
    //     config.data.email = email;
    //     config.data.password = password;
    //   }

    //   try {
    //     const response = await axios.request(config);
    //     console.log("response", response.statusText);
    //     const responseData = response.data;
    //     console.log("responseData", response);

    //     localStorage.setItem("token", responseData.jwt);
    //     localStorage.setItem("userId", responseData.user.id);
    //     localStorage.setItem("user", JSON.stringify(responseData.user));

    //     context.commit("setUser", {
    //       token: responseData.jwt,
    //       userId: responseData.user.id,
    //       user: responseData.user,
    //     });
    //   } catch (error) {
    //     if (
    //       mode === "signup" &&
    //       error.response &&
    //       error.response.status === 400
    //     ) {
    //       // Handle the case where the user is already signed up
    //       console.error(
    //         "User already exists:",
    //         error.response.data.message[0].messages[0].message
    //       );
    //       // Optionally, you can inform the user that the email is already registered
    //     } else {
    //       console.error(`${mode} error:`, error.response);
    //     }
    //   }
    // },
    // tryLogin(context) {
    //   const token = localStorage.getItem("token");
    //   const userId = localStorage.getItem("userId");
    //   const user = JSON.parse(localStorage.getItem("user"));

    //   try {
    //     if (token && userId) {
    //       context.commit("setUser", {
    //         token: token,
    //         userId: userId,
    //         user: user,
    //       });

    //       // Fetch the latest user data from the server
    //       context.dispatch("fetchUserData");
    //     }
    //   } catch (error) {
    //     console.log("errorMessage11", error);
    //     error;
    //   }
    // },
    async updateUserData(context, userData) {
      const token = context.state.token;
      if (!token) {
        console.error("No token found");
        return;
      }

      const userId = context.state.userId; // Assuming you have userId in the state
      try {
        const response = await axios.put(
          `${rootUrlApi}/users/${userId}`,
          userData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        context.commit("setUser", {
          token: token,
          userId: response.data.id,
          user: response.data,
        });
      } catch (error) {
        console.error("Error updating user data:", error);
        if (error.response) {
          console.error("Response error data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
    async fetchUserData(context) {
      const token = context.state.token;
      if (!token) return; // If there's no token, don't attempt to fetch data
      try {
        const response = await axios.get(`${rootUrlApi}/users/me?populate=*`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        context.commit("setUser", {
          token: token,
          userId: response.data.id,
          user: response.data,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async fetchUserDataOrders(context, orderId) {
      const token = context.state.token;
      if (!token) return; // If there's no token, don't attempt to fetch data
      try {
        const response = await axios.get(`${rootUrlApi}/users/me`, {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            populate: {
              orders: {
                filters: {
                  id: {
                    $eq: orderId,
                  },
                },
                populate: "*", // Populate all fields within the specific order
              },
            },
          },
        });

        context.commit("setUser", {
          token: token,
          userId: response.data.id,
          user: response.data,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    logout(context) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("user");
      // localStorage.removeItem("tokenExpiration");

      clearTimeout(timer);

      context.commit("setUser", {
        token: null,
        userId: null,
        user: null,
      });
    },
    autoLogout(context) {
      context.dispatch("logout");
      context.commit("setAutoLogout");
    },
  },
  getters: {
    userId(state) {
      return state.userId;
    },
    token(state) {
      return state.token;
    },
    userInfo(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return !!state.token;
    },
    didAutoLogout(state) {
      return state.didAutoLogout;
    },
    // getAddressUser(state) {
    //   return state.addresses;
    // },
  },
};
