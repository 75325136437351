import api from "../utils/globalApi";

export default {
  namespaced: true,
  state() {
    return {
      data: JSON.parse(sessionStorage.getItem("productDataCategory")) || [],
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productDataCategory", JSON.stringify(payload));
    },
  },
  actions: {
    async actionProducts(context) {
      try {
        const response = await api.getCategories(); // Use api.getCategories() to call the function
        const responseData = response.data;

        await context.commit("mutationProducts", responseData);
        // Construct the URL
      } catch (error) {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          throw new Error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.data;
    },
  },
};
