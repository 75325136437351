import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import store from "../store/index"; // Import your Vuex store
import { useHead } from "@vueuse/head";

const routes = [
  {
    path: "/",
    redirect: "/",
    name: "home",
    component: HomePage,
    meta: { title: "Home Page" },
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: { title: "Home Page" },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutUs.vue"),
    meta: { title: "About" },
  },
  {
    path: "/products?search=:title",
    name: "productPageWithPageTitle",
    props: true,
    component: () => import("../views/ProductPage.vue"),
    meta: { title: "Search" },
  },
  {
    path: "/products",
    name: "productPage",
    component: () => import("../views/ProductPage.vue"),
    meta: { title: "Products" },
  },
  {
    path: "/products/page/:id",
    name: "productPageWithPageId",
    props: true,
    component: () => import("../views/ProductPage.vue"),
  },
  {
    path: "/products/:id",
    name: "product-details",
    props: true,
    component: () => import("../views/ProductDetails.vue"),
    meta: {
      title: "Product Details",
    },
  },
  {
    path: "/contact",
    name: "ConatctUs",
    component: () => import("../views/ContactUs.vue"),
    meta: { title: "Contact" },
  },
  {
    path: "/wishlist",
    name: "wishlist",
    component: () => import("../views/WishlistPage.vue"),
    meta: { title: "Wishlist" },
  },
  {
    path: "/cart",
    name: "UserCart",
    component: () => import("../views/UserCart.vue"),
    meta: { title: "Cart" },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("../views/CheckOut.vue"),
    meta: { title: "Check out" },
  },
  {
    path: "/:notFound(.*)",
    name: "not-found",
    component: () => import("../views/NotFound.vue"),
    meta: { title: "Not Found" },
  },
  {
    path: "/auth/login",
    name: "login",
    component: () => import("../views/auth/LogIn.vue"),
    meta: { title: "Login", requiresUnauth: true },
  },
  {
    path: "/auth/register",
    name: "regitser",
    component: () => import("../views/auth/RegisTer.vue"),
    meta: { title: "Register", requiresUnauth: true },
  },
  {
    path: "/auth/reset-password",
    name: "resetpassword",
    component: () => import("../views/auth/ResetPassword.vue"),
    meta: { title: "Reset Password", requiresUnauth: true },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/userAccount/UserAccount.vue"),
    meta: { title: "Account", requiresAuth: true },
    children: [
      {
        path: "setting",
        name: "setting",
        component: () =>
          import("../views/userAccount/nestedAccount/AccountSetting.vue"),
        meta: { title: "User Setting", requiresAuth: true },
      },
      {
        path: "address",
        name: "address",
        component: () =>
          import("../views/userAccount/nestedAccount/UserAddress.vue"),
        meta: { title: "User Address", requiresAuth: true },
      },
      {
        path: "address/:id",
        props: true,
        name: "addressDetails",
        component: () =>
          import("../views/userAccount/nestedAccount/AddressDetails.vue"),
        meta: { title: "Address Details", requiresAuth: true },
      },
    ],
  },
  {
    path: "/order",
    name: "order",
    component: () => import("../views/userAccount/OrderHistory.vue"),
    meta: { title: "Order History", requiresAuth: true },
  },
  {
    path: "/order/:id",
    name: "orderDetails",
    props: true,
    component: () => import("../views/userAccount/OrderDetails.vue"),
    meta: { title: "Order Details", requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   // Store the previous route information in Vuex store
//   store.commit('mutationProducts', from);
//   next(); // Continue with navigation
// });

// router.beforeEach((to, from, next) => {
//   if (to.query.search) {
//     store.dispatch("product/getSearchProduct", to.query.search);
//   } else if (to.query.sort || to.params.id) {
//     store.dispatch("product/actionProducts", to.query.sort,to.params.id);
//     // store.dispatch("product/actionProducts", to.params.id);
//     console.log('to.query.sort',to.query.sort);
//     console.log('to.query.id',to.params.id);
//   } else {
//     store.dispatch("product/actionProducts", to.params.id);
//   }
//   next();
// });

// router.beforeEach((to, from, next) => {
//   if (to.query.search) {
//     store.dispatch("product/getSearchProduct", to.query.search);
//   } else if (to.query.sort || to.params.id) {
//     const pageNumber = to.params.id || 1;
//     const sort = to.query.sort || "";
//     store.dispatch("product/actionProducts", { pageNumber, sort });
//     console.log("to.query.sort", to.query.sort);
//     console.log("to.params.id", to.params.id);
//   } else {
//     store.dispatch("product/actionProducts",to.params.id );
//   }
//   next();
// });

// router.beforeEach((to, from, next) => {
//   const pageNumber = to.params.id || 1;
//   const sort = to.query.sort || "";
//   const category = to.query.category || "";
//   const brand = to.query.brand || "";

//   if (to.query.search) {
//     store.dispatch("product/getSearchProduct", to.query.search);
//   } else {
//     store.dispatch("product/actionProducts", {
//       pageNumber,
//       sort,
//       category,
//       brand,
//     });
//   }

//   next();
// });

// router.beforeEach((to, from, next) => {
//   const pageNumber = to.params.id || 1;
//   const sort = to.query.sort || "";
//   const categories = to.query.categories ? decodeURIComponent(to.query.categories).split('+') : [];
//   const brands = to.query.brands ? decodeURIComponent(to.query.brands).split('+') : [];

//   if (to.query.search) {
//     store.dispatch("product/getSearchProduct", to.query.search);
//   } else {
//     store.dispatch("product/actionProducts", { pageNumber, sort, categories, brands });
//   }

//   next();
// });

router.beforeEach((to, from, next) => {
  const { title } = to.meta;
  useHead({ title: title || "Page" });
  const pageNumber = to.params.id || 1;
  const pageSize = to.query.pageSize || 25;
  const sort = to.query.sort || "";
  const categories = to.query.categories
    ? decodeURIComponent(to.query.categories).split("+")
    : [];

  if (to.query.search) {
    store.dispatch("product/getSearchProduct", to.query.search);
  } else {
    store.dispatch("product/actionProducts", {
      pageNumber,
      pageSize,
      sort,
      filters: { categories },
    });
  }
  document.title = to.meta.title || "Default Title";
  if (to.meta.requiresAuth && !store.getters["auth/isAuthenticated"]) {
    next("/auth/login");
  } else if (to.meta.requiresUnauth && store.getters["auth/isAuthenticated"]) {
    // next("/account");
  }
  next();
});

export default router;
