import axios from "axios";

export default {
  namespaced: true,
  state() {
    const cartData = JSON.parse(sessionStorage.getItem("cart")) || [];
    const itemsInTheCart =
      JSON.parse(sessionStorage.getItem("itemsInTheCart")) || [];
    const addedNote = JSON.parse(sessionStorage.getItem("addNote")) || "";
    return {
      items: cartData,
      itemsInTheCart: itemsInTheCart,
      addedNote: addedNote,
      data: JSON.parse(sessionStorage.getItem("productData")) || [],
      total: cartData.reduce((sum, item) => sum + item.price * item.qty, 0),
      qty: cartData.reduce((sum, item) => sum + item.qty, 0),
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
      // console.log("mutationProducts state.data", state.data);
      sessionStorage.setItem("productData", JSON.stringify(payload));
    },
    addProductToCart(state, payload) {
      const {
        data: productData,
        qty,
        hellValue,
        weightValue,
        coffeeColorsValue,
        TotalPriceValue,
      } = payload;
      // Check if a similar product with different attributes already exists in the cart
      const productInCartIndex = state.items.findIndex(
        (ci) =>
          ci.productId === productData.id &&
          ci.hellValue === hellValue &&
          ci.weightValue === weightValue &&
          ci.coffeeColorsValue === coffeeColorsValue
      );

      if (productInCartIndex > -1) {
        // If the item exists, update the quantity
        state.items[productInCartIndex].qty += qty;
      } else {
        // If the item does not exist, add a new item
        const newItem = {
          productId: productData.id,
          title: productData.attributes.title,
          price: productData.attributes.price,
          old_price: productData.attributes.old_price,
          desc: productData.attributes.desc,
          image: productData.attributes.image,
          qty: qty,
          hellValue: hellValue,
          weightValue: weightValue,
          coffeeColorsValue: coffeeColorsValue,
          TotalPriceValue: TotalPriceValue,
        };
        state.items.push(newItem);
      }

      state.qty += qty;
      state.total += productData.attributes.price * qty;
      sessionStorage.setItem("cart", JSON.stringify(state.items));
    },
    // addProductToCart(state, payload) {
    //   const {
    //     data: productData,
    //     qty,
    //     hellValue,
    //     weightValue,
    //     coffeeColorsValue,
    //     TotalPriceValue
    //   } = payload;
    //   const productInCartIndex = state.items.findIndex(
    //     (ci) => ci.productId == productData.id
    //   );
    //   if (productInCartIndex > -1) {
    //     state.items[productInCartIndex].qty += qty;
    //   } else {
    //     const newItem = {
    //       productId: productData.id,
    //       title: productData.attributes.title,
    //       price: productData.attributes.price,
    //       old_price: productData.attributes.old_price,
    //       desc: productData.attributes.desc,
    //       image: productData.attributes.image,
    //       qty: qty,
    //       hellValue: hellValue,
    //       weightValue: weightValue,
    //       coffeeColorsValue: coffeeColorsValue,
    //       TotalPriceValue: TotalPriceValue,
    //     };
    //     state.items.push(newItem);
    //   }
    //   state.qty += qty;
    //   state.total += productData.attributes.price * qty;
    //   sessionStorage.setItem("cart", JSON.stringify(state.items));
    // },

    productInTheCart(state) {
      state.itemsInTheCart = state.items;
    },
    removeProductFromCart(state, payload) {
      const prodId = payload;
      const productInCartIndex = state.items.findIndex(
        (cartItem) => cartItem.productId == prodId
      );
      const prodData = state.items[productInCartIndex];
      state.items.splice(productInCartIndex, 1);
      state.qty -= prodData.qty;
      state.total -= prodData.price * prodData.qty;
      sessionStorage.setItem("cart", JSON.stringify(state.items));
    },
    removeOneProductFromCart(state, payload) {
      const {
        productId: productId,
        hellValue,
        weightValue,
        coffeeColorsValue,
      } = payload;

      // console.log("removeOneProductFromCart", payload);
      const productInCartIndex = state.items.findIndex(
        (ci) =>
          ci.productId === productId &&
          ci.hellValue === hellValue &&
          ci.weightValue === weightValue &&
          ci.coffeeColorsValue === coffeeColorsValue
      );
      if (productInCartIndex !== -1) {
        const prodData = state.items[productInCartIndex];
        if (prodData.qty > 1) {
          state.items[productInCartIndex].qty -= 1;
          state.qty -= 1;
          state.total -= prodData.price;
        } else {
          state.items.splice(productInCartIndex, 1);
          state.qty -= 1;
          state.total -= prodData.price;
        }
        sessionStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    // removeOneProductFromCart(state, payload) {
    //   const prodId = payload;
    //   const productInCartIndex = state.items.findIndex(
    //     (cartItem) => cartItem.productId == prodId
    //   );
    //   if (productInCartIndex != -1) {
    //     const prodData = state.items[productInCartIndex];
    //     // Decrease quantity by 1
    //     if (prodData.qty > 1) {
    //       state.items[productInCartIndex].qty -= 1;
    //       state.qty -= 1;
    //       state.total -= prodData.price;
    //     } else {
    //       // Remove the item if quantity becomes zero
    //       state.items.splice(productInCartIndex, 1);
    //       state.qty -= 1;
    //       state.total -= prodData.price;
    //     }
    //     // console.log("New Cart State:", state.items);
    //     sessionStorage.setItem("cart", JSON.stringify(state.items));
    //   }
    // },
    deleteAllProductFromCart(state) {
      state.items = [];
      state.qty = 0;
      state.total = 0;
      sessionStorage.removeItem("cart", JSON.stringify(state.items));
    },
    loadAddNoteData(state, payload) {
      state.addedNote = payload;
      sessionStorage.setItem("addNote", JSON.stringify(state.addedNote));
    },
  },
  actions: {
    // `/products?populate=*&filters[id][$eq]=${foundProduct}`,
    async actionProducts(context, foundProduct) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API +
            `/products?populate=*&filters[id][$eq]=${foundProduct}`,
          {
            headers: {
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
            },
          }
        );
        const responseData = response.data;
        // console.log("response.data", responseData);
        await context.commit("mutationProducts", responseData);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          throw new Error(error.response.data.message || "Network error");
        } else if (error.request) {
          console.log(error.request);
          throw new Error("No response received");
        } else {
          console.log("Error", error.message);
          throw new Error(error.message || "Error setting up the request");
        }
      }
    },
    async addToCart(context, payload) {
      const {
        id: prodId,
        qty,
        hellValue,
        weightValue,
        coffeeColorsValue,
        TotalPriceValue,
      } = payload;

      // const addedToCart = context.rootGetters;
      // console.log("token", payload);

      try {
        await context.dispatch("actionProducts", prodId);

        const products = context.getters.getProducts;

        // console.log("products after dispatch", payload);

        const product = products.data.find((prod) => prod.id == prodId);
        if (product) {
          context.commit("addProductToCart", {
            data: product,
            qty,
            hellValue,
            weightValue,
            coffeeColorsValue,
            TotalPriceValue,
          });
        } else {
          console.error("Product not found in products data");
        }
      } catch (error) {
        console.error("Error adding product to cart:", error);
      }
    },
    removeFromCart(context, payload) {
      context.commit("removeProductFromCart", payload);
    },
    removeOneFromCart(context, payload) {
      const {
        id: productId,
        qty,
        hellValue,
        weightValue,
        coffeeColorsValue,
        TotalPriceValue,
      } = payload;

      try {
        //  context.dispatch("actionProducts", prodId);

        const products = context.getters.products;

        // console.log("products after dispatch", products.find(id=>id.productId));

        const product = products.find((prod) => prod.productId == productId);
        if (product) {
          context.commit("removeOneProductFromCart", {
            productId: productId,
            qty,
            hellValue,
            weightValue,
            coffeeColorsValue,
            TotalPriceValue,
          });
        } else {
          console.error("Product not found in products data");
        }
      } catch (error) {
        console.error("Error adding product to cart:", error);
      }
      // console.log(payload)
      // context.commit("removeOneProductFromCart", payload);
    },
    deleteAllProduct(context) {
      context.commit("deleteAllProductFromCart");
    },
    setAddNote(context, payload) {
      context.commit("loadAddNoteData", payload);
    },
  },
  getters: {
    products(state) {
      return state.items;
    },
    getProducts(state) {
      return state.data;
    },
    totalSum(state) {
      return state.total;
    },
    quantity(state) {
      return state.qty;
    },
    getAddedNote(state) {
      return state.addedNote;
    },
  },
};
