import axios from "axios";
const rootUrlApi = process.env.VUE_APP_API;
export default {
  namespaced: true,
  state() {
    return {
      data: [],
      orderDetails: [],
      cancelOrder: null,
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productDataAddress", JSON.stringify(payload));
    },
    setCancelOrder(state, payload) {
      state.cancelOrder = payload;
    },
    setOrderDetails(state, orderDetails) {
      state.orderDetails = orderDetails;
    },
  },
  actions: {
    async cancelOrder(context, { event, orderId }) {
      const data = JSON.stringify({
        data: {
          Attention_customer_cancellation_order: event,
        },
      });
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${rootUrlApi}/orders/${orderId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
        },
        data: data,
      };
      try {
        const result = await axios.request(config);
        console.log(result.status);
        await context.commit("setCancelOrder", result.status);
      } catch (error) {
        console.log(error);
      }
    },

    async getOrderDetails({ commit }, { addressId, userId }) {
      // console.log(userId);
      // console.log("addressId", addressId);
      try {
        // Construct the URL to fetch address details by addressId and userId
        const url = `${rootUrlApi}/orders?populate=*&filters[id][$eq]=${addressId}&filters[user][id][$eq]=${userId}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
        });

        // console.log("response response", response);

        // Commit a mutation to update the state with the fetched address details
        commit("setOrderDetails", response.data);
      } catch (error) {
        console.error(
          "Error fetching address details:",
          error.response ? error.response.data : error.message
        );
      }
    },
    async getOrderData({ commit }, { userId, startDate }) {
      try {
        let url = `${rootUrlApi}/orders?populate=*&filters[user][id][$eq]=${userId}&sort=id:desc`;
        if (startDate) {
          url += `&filters[createdAt][$gte]=${startDate}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
        });

        commit("mutationProducts", response.data);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
      }
    },
  },
  getters: {
    getOrderData(state) {
      return state.data;
    },
    orderDetails(state) {
      return state.orderDetails;
    },
    cancelOrder(state) {
      return state.cancelOrder;
    },
  },
};
