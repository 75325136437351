<template>
  <div class=""><slot></slot></div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped>
div {
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 5px 0px #eee, 0px 0px 5px 0px #eee, 0px 0px 10px 1px #eee;
}
</style>
