<template>
    <div class="type_shipping ">
        <section class="horizontal mb-5">
            <div class="container">
                <div class="features">
                    <div class="feature-item">
                        <img src="../assets/images/moreImage/fastDelevery.png" alt="Fast Delivery" class="feature-image">
                        <h3>Fast Delivery</h3>
                        <p>All orders placed before 14:00 are shipped the same day.</p>
                    </div>
                    <div class="feature-item">
                        <img src="../assets/images/moreImage/ssl.png" alt="Secure Shopping" class="feature-image">
                        <h3>Secure Shopping</h3>
                        <p>Save your information and addresses to shop securely.</p>
                    </div>
                    <div class="feature-item">
                        <img src="../assets/images/moreImage/quickService.png" alt="Quick Support" class="feature-image">
                        <h3>Quick Support</h3>
                        <p>Get quick assistance by contacting our support team.</p>
                    </div>
                    <div class="feature-item">
                        <img src="../assets/images/moreImage/easyReturn.png" alt="Easy Return" class="feature-image">
                        <h3>Easy Return</h3>
                        <p>Returning any product is easy, no questions asked.</p>
                    </div>
                    <div class="feature-item">
                        <img src="../assets/images/moreImage/happyCustomers.png" alt="Happy Customers" class="feature-image">
                        <h3>Happy Customers</h3>
                        <p>Become one of our satisfied customers by shopping with us.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
.features {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    padding: 20px;
    background-color: #f9f9f9;
}

.feature-item {
    flex: 1 1 30%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
}

.feature-item:hover {
    transform: translateY(-10px);
}

.feature-image {
    max-width: 100px;
    margin-bottom: 15px;
}

h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
}

p {
    font-size: 1em;
    color: #555;
}

@media (max-width: 768px) {
    .feature-item {
        flex: 1 1 100%;
    }
}
</style>