<template>
    <div class="">{{ title }}</div>
</template>

<script>
export default {
    props: ['title'],
    data() {
        return {

        }
    }
}

</script>