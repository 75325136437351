<template>
  <the-header></the-header>
  <router-view />
  <the-footer></the-footer>
</template>

<script>
import TheFooter from './components/layout/TheFooter.vue';
import TheHeader from './components/layout/TheHeader.vue';

export default {
  data() {
    return {}
  },
  components: { TheHeader, TheFooter }
}

</script>


<style>



/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Noto+Sans+Arabic:wght@400;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
}

.arabic-text {
  font-family: 'Noto Sans Arabic', sans-serif;
} */




@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Cairo:wght@400;700&display=swap');

/* body {
  font-family: 'Montserrat', sans-serif;
} */



#app {
  font-family: 'Cairo', 'Montserrat', sans-serif;
}
</style>
