import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import EventBus from "./event-bus"; // Import the event bus
import { createHead } from "@vueuse/head";

window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";

// Import Bootstrap JavaScript
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "./assets/css/global.css";
import "./assets/css/style.css";

// Default theme
import "@splidejs/vue-splide/css";

// or other themes
import "@splidejs/vue-splide/css/skyblue";
import "@splidejs/vue-splide/css/sea-green";

// or only core styles
import "@splidejs/vue-splide/css/core";

import BaseButton from "./components/ui/BaseButton.vue";

import BaseSpinner from "./components/ui/BaseSpinner.vue";
import BaseimageSpinner from "./components/ui/BaseimageSpinner.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import BaseLoader from "./components/ui/BaseLoader.vue";
import BaseqtySpinner from "./components/ui/BaseqtySpinner.vue";

import BaseAddToCart from "./components/ui/BaseAddToCart.vue";
// import AddToCart from "./components/cart/AddToCart.vue";
import BaseBox from "./components/ui/BaseBox";
import BaseCard from "./components/ui/BaseCard";
import ProductCard from "./components/product/ProductCard.vue";
import ProductPrice from "./components/product/ProductPrice.vue";
import ProductTitle from "./components/product/ProductTitle.vue";

import VueSplide from "@splidejs/vue-splide";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const app = createApp(App);

const head = createHead();

app.component("base-dialog", BaseDialog);

app.component("base-add-to-cart", BaseAddToCart);
app.component("base-button", BaseButton);
app.component("base-box", BaseBox);
app.component("base-card", BaseCard);
app.component("base-loader", BaseLoader);
app.component("baseimage-spinner", BaseimageSpinner);
app.component("baseqty-spinner", BaseqtySpinner);

app.component("base-spinner", BaseSpinner);
app.component("product-card", ProductCard);
app.component("product-price", ProductPrice);
app.component("product-title", ProductTitle);

app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.use(VueSplide);
app.use(head);

app.config.globalProperties.$bus = EventBus; // Make the event bus available globally

app.mount("#app");
