<template>
    <div class="card">
        <slot></slot>
    </div>
</template>
  
<style scoped>
.card {
    border-radius: 3px;
    box-shadow: 0 2px 8px #e1e8ee;
    padding: 1rem;
    margin: 2rem auto;
}
</style>