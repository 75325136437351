<template>
  <!-- Start Header -->
  <header>
    <base-spinner v-if="isLoading"></base-spinner>

    <div class="offers_text text-center p-3">
      <p class="m-0 p-0">Shipping fee over 1500 TL is on us.</p>
    </div>
    <div class="social_media d-flex justify-content-between align-items-center p-2 ps-4 pe-4">
      <div class="select_language">
        <label for="lan">Language:</label>
        <select name="" id="lan" v-model="currentLanguage" @change="changeLanguage">
          <option value="en" lang="en" selected>English</option>
          <option value="ar" lang="ar">العربية</option>
          <option value="tr" lang="tr">Türkçe</option>
        </select>
      </div>
      <div class="d-flex justify-content-between align-items-center gap-3">
        <div>
          <i class="bi bi-facebook"></i>
        </div>
        <div>
          <i class="bi bi-whatsapp"></i>
        </div>
        <div>
          <i class="bi bi-instagram"></i>
        </div>
        <div>
          <i class="bi bi-tiktok"></i>
        </div>
      </div>
    </div>
    <!-- Hide Mobile -->
    <div class="hide_mobile container pt-3 pb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="main_search_logo d-flex align-items-center">
          <router-link to="/">
            <img class="mainLogo" src="../../assets/images/mainLogo.png" alt="">
          </router-link>
          <div class="search_box ">
            <button class="pointer search_box_btn " @click="isActive = !isActive">
              <i class="bi bi-search"></i>
            </button>
          </div>

          <button class="search_box_getSearch" v-if="getSearchQuery" @click="clearSearch">
            <div>{{ getSearchQuery }}</div> <i class="bi bi-x fs-4 pointer"></i>
          </button>



          <div class="search_box relative">


            <div class="top-bar-search" :class="isActive ? 'active' : ''">
              <button class="search-close" @click="isActive = !isActive">
                <i class="bi bi-x fs-4 pointer ms-2 me-2"></i>
              </button>
              <div class="search_inbut_head">
                <input type="text" @keyup.enter="onEnter" v-model="searchQuery" placeholder="Search...">
                <button class="clear_search" v-if="searchQuery" @click="clearSearch">
                  <i class="bi bi-x fs-4 pointer ms-2 me-2"></i>
                </button>
                <div class="pointer search_box_head_btn " @click="onEnter">
                  <i class="bi bi-search"></i>
                </div>
              </div>
              <div class="search-overlay" @click="isActive = !isActive"></div>
            </div>

          </div>
        </div>
        <ul class="cart_lgoin_regisret_box  m-0 p-0 d-flex gap-4 justify-content-between align-items-center">

          <li class="relative">
            <router-link :to="authOrAccount" style="color: #312718;">
              <div class="background_login_cart">
                <i class="bi bi-person"></i>
              </div>

              <div class="text_reg_log_cart" v-if="isLoggedIn">
                <h4 class="fw-bold">{{ username?.username }}</h4>
              </div>

              <div class="text_reg_log_cart" v-else>
                <span>{{ messages.messages.account.register }}</span>
                <h4 class="fw-bold">{{ messages.messages.account.login }}</h4>
              </div>
            </router-link>

          </li>
          <li class="text_reg_log_cart">
            <router-link to="/cart" style="color: #312718;">
              <div class="relative background_login_cart">
                <span class="cart_count">{{ cartTotalQty }}</span>
                <i class="bi bi-basket3"></i>
              </div>
              <span class="fs-6 fw-bold">{{ messages.messages.shop.cart }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class=" main_category_boxs pt-4 pb-4 mt-3">
      <div class="container">
        <swip-freemode></swip-freemode>
      </div>
    </div>

  </header>
  <!-- End Header -->
</template>


<script>
import messageEn from '../../locales/en-US.json';
import messageAr from '../../locales/ar-AR.json';
import messageTr from '../../locales/tr-TR.json';
import EventBus from '../../event-bus'; // Import the event bus
import SwipFreemode from './SwipFreemode.vue';

export default {
  components: { SwipFreemode },
  data() {
    return {
      currentLanguage: localStorage.getItem('language') || 'en', // Default language is English
      messages: this.getMessages(localStorage.getItem('language') || 'en'),// Start with language from localStorage or English messages
      searchQuery: '',
      isActive: false,
      isLoading: false,
    }
  },
  computed: {
    cartTotalQty() {
      return this.$store.state.cart.qty;
    },
    getSearchQuery() {
      return this.$route.query.search
    },
    isLoggedIn() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    username() {
      return this.$store.getters['auth/userInfo'];
    },
    authOrAccount() {
      if (this.isLoggedIn) {
        return '/account'
      } else {
        return '/auth/login'
      }
    },
    orderOrAccount() {
      if (this.isLoggedIn) {
        return '/order'
      } else {
        return '/auth/login'
      }
    },

  },
  methods: {
    async logout() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('auth/logout');
        this.$router.replace('/');
        this.isLoading = true;
      }
      catch (error) {
        error.response
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    onEnter() {
      if (this.searchQuery) {
        this.$router.push({ path: '/products', query: { search: this.searchQuery } });
      }
      this.isActive = !this.isActive
    },
    clearSearch() {
      this.searchQuery = '';
      this.$router.push({ path: '/products', query: {} });
    },

    changeLanguage() {
      // Update messages based on the selected language
      localStorage.setItem('language', this.currentLanguage);
      this.messages = this.getMessages(this.currentLanguage);
      EventBus.emit('language-changed', this.currentLanguage); // Emit language change event
      window.location.reload();
    },
    getMessages(language) {
      switch (language) {
        case 'en':
          return messageEn;
        case 'ar':
          return messageAr;
        case 'tr':
          return messageTr;
        default:
          return messageEn; // Fallback to English if language is not recognized
      }
    },
  },
  watch: {
    currentLanguage(newLanguage) {
      // Update page direction based on selected language
      document.documentElement.dir = newLanguage === 'ar' ? 'ltr' : 'ltr';
    }
  },
  created() {

    // Set the initial language direction
    document.documentElement.dir = this.currentLanguage === 'ar' ? 'ltr' : 'ltr';
  },
  mounted() {
    this.searchQuery = this.$route.query.search

  }
}
</script>

<style scoped>
* {
  /* font-family: system-ui; */
}



.select_language {
  display: flex;
  align-items: center;
}


.select_language select {
  background: #312718;
  color: #fff;
  border-radius: 5px;
}

.active {
  opacity: 1;
  visibility: visible;
}


@media (max-width: 576px) {
  .text_reg_log_cart {
    display: none;
  }

  .search_box_btn {
    /* display: none; */
  }
}
</style>