import axios from "axios";

const axiosClient = axios.create({ baseURL: process.env.VUE_APP_API });

const getCategories = () =>
  axiosClient.get(
    "/categories?populate=*&pagination[page]=1&pagination[pageSize]=50",
    {
      headers: {
        Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
      },
    }
  );
const loadNewProduct = () =>
  axiosClient.get("/products?populate=*&filters[is_new][$eq]=true", {
    headers: {
      Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
    },
  });

const offerDataProducts = () =>
  axiosClient.get("/products?populate=*&filters[offer][$eq]=true", {
    headers: {
      Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
    },
  });

const postAuthRegisrt = () => axiosClient.post("/auth/local/register");

export default {
  postAuthRegisrt,
  getCategories,
  loadNewProduct,
  offerDataProducts,
};
