import axios from "axios";
const rootUrlApi = process.env.VUE_APP_API;
export default {
  namespaced: true,
  state() {
    return {
      data: [],
      //   orderDetails: [],
    };
  },
  mutations: {
    mutationProducts(state, payload) {
      state.data = payload;
      sessionStorage.setItem("productDataAddress", JSON.stringify(payload));
    },
    // setAddressDetails(state, orderDetails) {
    //   state.orderDetails = orderDetails;
    // },
  },
  actions: {
    
    async setOrderWithoutUser(_, data) {

      const requestData = JSON.stringify({
        data: {
          username: data.username,
          email: data.email,
          phone_number: data.phone_number,
          address: data.address,
          country: data.country,
          city: data.city,
          the_order: data.the_order,
          order_summary: data.order_summary,
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${rootUrlApi}/order-without-users`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
        },
        data: requestData,
      };
      try {
        await axios.request(config);

      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.data;
    },
  },
};
